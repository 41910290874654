<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="nft_card nft_qr">
						<p class="qr_tit">UTILITIES CARD</p>
					</div>

					<div
						v-if="is_use"
						class="view_cont_wrap"
					>
						<p class="pop_desc">
							유틸리티 카드를 해제 하시겠습니까?
							<span>해제하시면 해당 강화능력을 사용할 수 없습니다.</span>
						</p>
						<slot name="content"></slot>
					</div>

					<div
						v-else
						class="view_cont_wrap"
					>
						<p class="pop_desc">
							유틸리티 카드를 장착 하시겠습니까?
							<span>장착하시면 해당 강화능력을 사용할 수 있습니다.</span>
						</p>
						<slot name="content"></slot>
					</div>

					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">취소</button>
						<button
							v-if="is_use"
							class="pop_btn btn_fill_blue" @click="$emit('click', utility)"
						>해제</button>
						<button
							v-else
							class="pop_btn btn_fill_blue" @click="$emit('click', utility)"
						>장착</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

export default {
	name: 'mafia0193'
	, props: ['utility']
	, data: function(){
		return {

		}
	}
	, computed: {
		is_use: function () {

			let t = false
			if (this.utility.utilty_card_mount_fg == 'Y') {
				t = true
			}
			return t
		}
	}
	, methods: {

	}
	,created() {
		this.$log.console('created 0193')
		this.$log.console(this.utility)
	}
}

</script>
