<template>
	<div class="mt-30 text-left">
		<div class="under-line-bbb pb-10 flex-row align-center">
			<h3 class="flex-1 size-px-20">NFT 컬렉션</h3>
			<div>
				<button
					v-if="false"
					class="btn-inline btn-primary radius-20 mr-10"
				><img :src="require('@/assets/image/web/nft_collection/ico_history.svg')" class="mr-10">NFT 내역</button>

				<button
					class="btn-inline btn-primary radius-20 pa-10-30 mr-10"
					@click="toSwap"
				><img :src="require('@/assets/image/icon-swap.svg')" class="mr-10">SWAP</button>
				<button
					class="btn-inline btn-primary radius-20 pa-10-30"
					@click="on023"
				><img :src="require('@/assets/image/web/nft_collection/ico_deposit.svg')" class="mr-10">입금</button>
			</div>
		</div>
		<div>
			<div class="pt-30 ">
				<div>
					<h3 class="size-px-16 color-333">Union Card</h3>
				</div>
				<ul
					v-if="items_card_list.union.length > 0"
					class="mt-20"
				>
					<li
						v-for="(item, index) in items_card_list.union"
						:key="'item_' + index"
						class="inline-block box-nft-card position-relative mb-20 cursor-pointer"
						@click="toDetail(item)"
					>
						<div class="">
							<img
								:src="$request.upload_url(item.nft_card_img_url)" :alt="item.name"
								class="width-100"
							>
							<span
								class="badge_30 union_badge"
								:class="'badge_30_' + item.badge"
							><em class="hide"> {{ item.badge }}</em></span>

							<div
								v-if="item.stakg_fg == 'Y'"
								class="bg-lock position-absolute-full radius-20"
							><span class="hide">스테이킹중인 카드</span></div>
						</div>
					</li>
				</ul>

				<div
					v-else
					class="mt-30 none"
				>보유한 NFT가 없습니다.</div>
			</div>

			<hr
				class="mt-10 under-line"
			/>

			<div class="mt-30">

				<div>
					<h3 class="size-px-16 color-333">Mafia Card</h3>
				</div>
				<ul
					v-if="items_card_list.nft.length > 0"
					class="mt-20"
				>
					<li
						v-for="(item, index) in items_card_list.nft"
						:key="'item_' + index"
						class="inline-block box-nft-card position-relative mb-20 cursor-pointer"
						@click="toDetail(item)"
					>
						<div class="">
							<img
								:src="$request.upload_url(item.cartl_nft_img_url)" :alt="item.cartl_nft_catgy_name"
								@error="$event.target.src=require('@/assets/image/main_card2.png')"
								class="width-100 radius-20"
							>
							<span
								class="badge_30 union_badge"
								:class="'badge_30_' + item.badge"
							></span>

							<div
								v-if="item.stakg_fg == 'Y'"
								class="bg-lock position-absolute-full radius-20"
							></div>
						</div>
					</li>
				</ul>

				<div
					v-else
					class="mt-30 none"
				>보유한 NFT가 없습니다.</div>
			</div>
		</div>

		<mafia021
			v-if="is_021"
			:is_dual="true"
			:items="items"
			@to="is_021 = !is_021; is_022 = !is_022"
			@click="is_021 = false"
		></mafia021>

		<mafia022
			v-if="is_022"
			:is_dual="true"
			@to="is_022 = !is_022; is_021 = !is_021"
			@click="is_022 = false"
		></mafia022>

		<mafia023
			v-if="is_023"
			:wallet="wallet"

			@cancel="is_023 = false"
			@click="is_023 = false"
		></mafia023>

		<mafia0193
			v-if="is_0193"
			:utility="item_utility"

			@cancel="is_0193 = false"
		>

		</mafia0193>

		<mafia029
			v-if="is_029"
			:nft="item_main_nft"
			:Axios="Axios"
			:sample="sample"
			:user="user"

			@cancel="is_029 = false"
			@click="getData"
		></mafia029>

		<PopupConfirm
			v-if="is_popup"

			@click="$emit('to', { name: 'wallet'})"
			@cancel="is_popup = false"
		>
			<template v-slot:title>NFT 입금</template>
			<template v-slot:main-txt>지갑이 없습니다</template>
			<template v-slot:sub-txt>지갑을 생성 후 NFT를 받을 수 있습니다.</template>
			<template v-slot:name_confirm>지갑 생성</template>
		</PopupConfirm>
	</div>
</template>

<script>

import Mafia021 from "@/view/Nft/mafia021";
import Mafia022 from "@/view/Nft/mafia022";
import Mafia023 from "@/view/Nft/mafia023";
import Mafia029 from "@/view/Nft/mafia029";
import Mafia0193 from "@/view/Nft/mafia019-3";
import PopupConfirm from "@/view/Layout/PopupConfirm";

export default {
	name: 'mafia018'
	, components: {PopupConfirm, Mafia021, Mafia022, Mafia023, Mafia029, Mafia0193}
	, props: ['Axios', 'sample', 'user']
	, data: function(){
		return {
			program: {
				name: 'NFT'
			}
			, is_021: false
			, is_022: false
			, is_023: false
			, is_029: false
			, is_0193: false
			, is_popup: false
			, items: []
			, items_nft: []
			, items_utility: []
			, item_main_nft: {}
			, item_utility: {}
			, wallet: {}
		}

	}
	, computed: {
		is_count: function(){

			let t = {
				union: 0
				, nft: 0
			}
			t.union = this.items_card_list.union.length

			if(t.union == 0){
				t.union = 0
			}else{
				t.union = (t.union % 5) == 0 ? 0 : 5 - t.union % 5
			}


			t.nft = this.items_card_list.nft.length

			if(t.nft == 0){
				t.nft = 0
			}else{
				t.nft = (t.nft % 5) == 0 ? 0 : 5 - t.nft % 5
			}

			return t
		}
		, main_nft_badge: function(){
			let t = ''

			switch (this.item_main_nft.mafi_nft_card_div_code){
				case 'NF00100001':
					t = 'music'
					break;
				case 'NF00100002':
					t = 'art'
					break;
				case 'NF00100003':
					t = 'fashion'
					break;
				case 'NF00100004':
					t = 'influence'
					break;

			}

			return t
		}
		, items_card_list: function(){

			let t = {
				nft: []
				, union: []
			}

			this.items.filter(function(item){
				if(item.nft_card_div_code == 'NF00100004') {
					item.badge = 'utilities'
					item.card_type = 'utility'
					t.nft.push(item)
				}else if(item.nft_card_div_code == 'NF00100005') {
					item.badge = 'union'
					item.card_type = 'union'
					t.union.push(item)
				}else{
					item.card_type = 'nft'
					switch (item.nft_card_grade_code){
						case 'NF00200021':
							item.badge = 'music'
							break;
						case 'NF00200022':
							item.badge = 'art'
							break;
						case 'NF00200023':
							item.badge = 'fashion'
							break;
						case 'NF00200024':
							item.badge = 'influence'
							break;
					}
					t.nft.push(item)
				}

				return item
			})

			this.items_nft.filter(function(item){
				item.card_type = 'nft'
				item.badge = (item.cartl_nft_catgy_name).toLowerCase()

				t.nft.push(item)

				return item
			})

			return t

		}
	}
	,methods: {

		getData:  async function(){
			this.$log.console('mafia018 getdata')
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_main_nft
					,data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){

					this.item_main_nft = result.data.mafi_card_info
					switch (this.item_main_nft.mafi_nft_card_div_code){
						case 'NF00100004':
							this.$set(this.item_main_nft, 'card_type', 'utility')
							break;
						case 'NF00100005':
							this.$set(this.item_main_nft, 'card_type', 'union')
							if(!this.item_main_nft.cartl_nft_number){
								this.$set(this.item_main_nft, 'nft_card_number', this.item_main_nft.mafi_nft_card_number)
							}
							break;
						default:
							this.$set(this.item_main_nft, 'card_type', 'nft')
							break;
					}
					this.items_utility = result.data.utilty_card_list
					this.items = result.data.holding_mafi_card_list
					this.items_nft = result.data.holding_cartl_nft_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getUtilityList: async function(){
			try{

				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_utility_list
					,data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_utility = result.data
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, to0192: function(num){
			this.$emit('to', {name: 'mafia0192', params: {card_type: 'utility', id: num}})
		}
		, on0192: function(){
			this.is_029 = !this.is_029
		}
		, on023: async function(){
			try{
				//this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: 'BC00100001'
					}
					, type:true
				})
				if(result.success){

					this.wallet = result.data
					if(this.wallet.wallet_address){
						this.is_023 = true
					}else{
						throw '지갑 주소가 없습니다.'
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$log.console('item.card_type', item)
			if(item.card_type == 'nft'){
				this.$emit('to', { name: 'mafia019', params: {card_type: item.card_type, id: item.cartl_nft_number }})
			}else if(item.card_type == 'union') {
				this.$emit('to', { name: 'mafia0191', params: {card_type: item.card_type, id: item.nft_card_number }})
			}else{
				this.$emit('to', { name: 'mafia019', params: {card_type: item.card_type, id: item.nft_card_number }})
			}
		}
		, toSwap: function(){
			this.$bus.$emit('to', { name: 'NftSwap'})
		}
	}
	,async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
		//await this.getMainNft()
		//await this.getUtilityList()
	}
}
</script>

<style>
</style>