<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class=" ma-auto width-400 radius-20 pa-40-30 bg-white position-relative" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="flex-row justify-center">
					<h4 class="">MAFIA NFT 입금</h4>
					<div class="popup-close">
                        <img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
                    </div>
				</div>

				<div class="justify-center flex-column align-center gap-20 mb-20 mt-20">
					<img :src="require('@/assets/image/web/ico_logo_small.svg')" />
					<p class="size-px-24 font-weight-700" style="color:var(--mafia-Blue)">MAFIA NFT CARD</p>
				</div>

				<div class="text-center mt-20 ma-auto qr_box" style="width: 160px;">
					<div
						v-if="wallet"
					>

						<div
							style="width: 160px"
							class="ma-auto"
						>
							<qr-code :text="wallet.wallet_address"></qr-code>
						</div>
						<div class="mt-20 flex-row justify-space-between items-center">
							<p class="size-px-16" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{ wallet.wallet_address }}</p>
							<button class="btn_copy" @click="copy"><v-icon class="color-333">mdi-content-copy</v-icon></button>
						</div>
					</div>

					<div
						v-else
						class="none"
					>생성된 지갑이 없습니다</div>

					<div class="mt-20">
						<button class="btn-inline btn-primary radius-20 pa-10-30 font-weight-500" @click="$emit('click')">확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

	import Vue from 'vue'
	import VueQRCodeComponent from 'vue-qrcode-component'
	import PopupLayer from "@/view/Layout/PopupLayer";
	Vue.component('qr-code', VueQRCodeComponent)

	export default {
		name: 'mafia023'
		, components: {PopupLayer}
		, props: ['wallet']
		, data: function(){
			return {
			}
		}
		, methods: {
			clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (){
				this.clipBoard(this.wallet.wallet_address);
				this.$bus.$emit('notify',  { type: 'success', message: '지갑주소가 복사되었습니다'})
			}
		}
	}

</script>

<style>
	figure img { display: inline !important;}
</style>